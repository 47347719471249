// import { type ClassValue, clsx } from "clsx"
// import { twMerge } from "tailwind-merge"

// export function cn(...inputs: ClassValue[]) {
//   return twMerge(clsx(inputs))
// }

// https://akhilaariyachandra.com/snippets/using-clsx-or-classnames-with-tailwind-merge
import type { ClassValue } from 'clsx';

import { clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({});

export function cn(...args: ClassValue[]) {
  const modifiedClasses = clsx(args);
  const mergedClasses = customTwMerge(modifiedClasses);

  return mergedClasses;
}
